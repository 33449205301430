<template>
  <h1 v-if="false">Chat Invite</h1>
</template>

<script>
export default {
  name: "MeshInvite",
  computed: {
    userData () {
      return { ...this.$store.getters["User/userData"] }
    }
  },
  async beforeMount () {
    let groupId = this.$route.query.id
    if (groupId && this.userData.user_id) {
      let meshUrl = process.env.VUE_APP_MESH_SERVER_URL + `/gateway/${this.userData.user_id}?id=${groupId}`
      this.$store.dispatch("MeshUrl/setMeshUrl", meshUrl)
      this.$store.dispatch("OpenMesh/setOpenMesh", true)
      this.$router.push("/home")
    }
  }
}
</script>

<style>

</style>
